<template>
  <div>
    <b-form
      id="test-api-form"
      name="test-api-form"
      @submit.prevent="onSubmitTestAPI"
      autocomplete="off"
      novalidate
    >
      <b-form-row>
        <b-col sm="8">
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                id="api-endpoint-to-test"
                :class="{
                  'is-invalid':
                    (!testAPIModel.apiUrl && formSubmitted) ||
                    (!validateUrl(testAPIModel.apiUrl) && formSubmitted),
                }"
                type="text"
                placeholder=" "
                v-model="testAPIModel.apiUrl"
                required
              ></b-form-input>
              <label for="api-endpoint-to-test">API endpoint to test</label>
              <b-form-invalid-feedback
                class="d-block"
                v-if="!testAPIModel.apiUrl && formSubmitted"
                >API endpoint to test required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                class="d-block"
                v-if="
                  testAPIModel.apiUrl &&
                  formSubmitted &&
                  !validateUrl(testAPIModel.apiUrl)
                "
                >Invalid url.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <template v-if="testAPIModel.widgetCategoryID == 5">
        <b-form-row>
          <b-col sm="8">
            <b-form-group>
              <div class="floating-select-field">
                <b-form-select
                  id="link-account"
                  class="form-control"
                  v-model="testAPIModel.linkAccountDetails"
                  @change="onChangeLinkedAccount"
                  @input="testAPIModel.linkAccountDetails = $event"
                  :class="{
                    'is-value-exist': testAPIModel.linkAccountDetails != null,
                    'is-invalid':
                      !testAPIModel.linkAccountDetails && formSubmitted,
                  }"
                >
                  <b-form-select-option
                    :value="linkAccount"
                    v-for="(linkAccount, index) in getFilterdLinkAccounts"
                    :key="index"
                    >{{ linkAccount.accountName }}</b-form-select-option
                  >
                </b-form-select>
                <label for="link-account">Select link account</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!testAPIModel.linkAccountDetails && formSubmitted"
                  >Link account required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group></b-col
          >
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group>
              <h4 class="page-sub-header">Access token</h4>
              <b-form-radio-group
                v-model="testAPIModel.isManual"
                name="test-api-access-token-radio"
                @change="onChangeIsManualAccessToken"
                aria-describedby="access token selection"
              >
                <b-form-radio :value="true">Manual </b-form-radio>
                <b-form-radio :value="false">User based</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="testAPIModel.isManual">
          <b-col sm="8">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  id="access-token"
                  :class="{
                    'is-invalid': !testAPIModel.accessToken && formSubmitted,
                  }"
                  type="text"
                  placeholder=" "
                  v-model="testAPIModel.accessToken"
                  required
                ></b-form-input>
                <label for="access-token">Access token</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!testAPIModel.accessToken && formSubmitted"
                  >Access token required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <template v-if="!testAPIModel.isManual">
          <b-form-row v-if="showLinkedAccountErrorMsg">
            <b-col>
              <h6 class="font-14">
                <InfoCircelYellow class="mr-1 mt-n1" />
                {{ DISPLAY_MESSAGES.TEST_API_LINKED_USERLIST_ERROR }}
              </h6>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="8">
              <b-form-group>
                <div class="floating-select-field">
                  <v-select
                    label="email"
                    :reduce="(email) => email.userId"
                    :disabled="
                      !linkedAccountUsersList.length && !userSearchTerm
                    "
                    :options="linkedAccountUsersList"
                    v-model="testAPIModel.userId"
                    @open="focusedUser = true"
                    @close="focusedUser = false"
                    @search="onSearchUsers"
                    @input="getSelectedAddress"
                    :class="{
                      'is-value-exist': testAPIModel.userId || focusedUser,
                      'is-invalid': !testAPIModel.userId && formSubmitted,
                    }"
                  >
                    <template slot="no-options" slot-scope="{ search }">
                      {{
                        search
                          ? `No users found.`
                          : `Start typing user, and select it from the
                      dropdown.`
                      }}
                    </template>
                    <template slot="option" slot-scope="option">
                      {{ option.email }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.email }}
                    </template>
                  </v-select>
                  <label for="calendar-type">Select user</label>
                  <b-form-invalid-feedback
                    class="d-block"
                    v-if="!testAPIModel.userId && formSubmitted"
                    >User required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="testAPIModel.isPropertyDependent">
            <b-col sm="8">
              <b-form-group>
                <div class="floating-select-field">
                  <v-select
                    label="fullAddress"
                    id="id"
                    :disabled="
                      !testAPIModel.userId ||
                      !linkedAccountUserAddressesList.length
                    "
                    :clear-search-on-blur="
                      (clearSearchOnSelect, multiple) =>
                        clearSearchOnSelect && !multiple
                    "
                    :options="linkedAccountUserAddressesList"
                    :reduce="(fullAddress) => fullAddress"
                    v-model="testAPIModel.selectedAddress"
                    :class="{
                      'is-value-exist': testAPIModel.selectedAddress,
                      'is-invalid':
                        !testAPIModel.selectedAddress && formSubmitted,
                    }"
                  >
                    <template slot="no-options" slot-scope="{ search }">
                      {{
                        search
                          ? `No address found.`
                          : `Start typing address, and select it from the
                      dropdown.`
                      }}
                    </template>
                    <template slot="option" slot-scope="option">
                      {{ option.fullAddress }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.fullAddress }}
                    </template>
                  </v-select>
                  <label for="calendar-type">Select address</label>
                  <b-form-invalid-feedback
                    class="d-block"
                    v-if="!testAPIModel.selectedAddress && formSubmitted"
                    >Address required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
        </template>
      </template>
      <template
        v-if="testAPIModel.isManual && testAPIModel.isPropertyDependent"
      >
        <b-form-row>
          <b-col sm="8">
            <b-form-group>
              <div class="floating-select-field">
                <v-select
                  label="name"
                  :id="`street-name`"
                  :filterable="false"
                  :class="{
                    'is-value-exist':
                      testAPIModel.streetName || focusedStreetName,
                    'is-invalid': !testAPIModel.streetName && formSubmitted,
                  }"
                  :options="streetNames"
                  v-model="testAPIModel.streetName"
                  @open="focusedStreetName = true"
                  @close="focusedStreetName = false"
                  @search="onSearchStreetNames"
                  @input="getStreetNumbers($event)"
                >
                  <template slot="no-options" slot-scope="{ search }">
                    {{
                      search && search.trim()
                        ? `No street names found.`
                        : `Start typing your street name, and select it from the
                      dropdown.`
                    }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.name }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.name }}
                  </template>
                </v-select>
                <label :for="`street-name`">Street name</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!testAPIModel.streetName && formSubmitted"
                  >Street name required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="4">
            <b-form-group>
              <div class="floating-select-field">
                <select
                  v-model="testAPIModel.streetNumber"
                  @input="testAPIModel.streetNumber = $event.target.value"
                  @change="getUnitNumbers($event.target.value)"
                  :id="`street-number`"
                  :disabled="!testAPIModel.streetName"
                  class="form-control"
                  :class="{
                    'is-value-exist': testAPIModel.streetNumber,
                    'is-invalid': !testAPIModel.streetNumber && formSubmitted,
                  }"
                >
                  <option
                    v-for="(streetNumber, si) in streetNumbers"
                    :value="streetNumber"
                    :key="si"
                  >
                    {{ streetNumber }}
                  </option>
                </select>
                <label :for="`street-number`">Street number</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!testAPIModel.streetNumber && formSubmitted"
                  >Street number required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group>
              <div class="floating-select-field" v-if="unitNumbers">
                <select
                  v-model="testAPIModel.unitNumber"
                  @input="testAPIModel.unitNumber = $event.target.value"
                  @change="testAPIModel.unitNumber = $event.target.value"
                  :id="`unit-number`"
                  :disabled="!testAPIModel.streetNumber || !unitNumbers.length"
                  class="form-control"
                  :class="{
                    'is-value-exist': testAPIModel.unitNumber,
                    'is-invalid':
                      unitNumbers.length &&
                      !testAPIModel.unitNumber &&
                      formSubmitted,
                  }"
                >
                  <option
                    v-for="(unitNumber, ui) in unitNumbers"
                    :value="unitNumber"
                    :key="ui"
                  >
                    {{ unitNumber }}
                  </option>
                </select>
                <label :for="`unit-number`">Apt / unit number</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    unitNumbers.length &&
                    !testAPIModel.unitNumber &&
                    formSubmitted
                  "
                  >Apt / unit number required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
      </template>
      <b-form-row class="mt-3">
        <b-col sm="8">
          <b-button
            type="submit"
            v-activeBlur
            variant="secondary"
            :disabled="saveLoadingIcon"
            >Run
            <b-spinner
              v-if="saveLoadingIcon"
              label="Spinning"
              small
              class="ml-2"
            ></b-spinner
          ></b-button>
          <b-button
            type="button"
            v-activeBlur
            class="ml-3"
            @click="onResetTestAPIForm"
            variant="outline-secondary"
            >Cancel</b-button
          >
        </b-col>
      </b-form-row>
    </b-form>
    <TestAPITabs :apiResponse="apiResponse" :tabs="responseTabs" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import { useValidateFields } from '@/composables/useValidateFields'
import { DISPLAY_MESSAGES } from '../../../../utilities/constants'
import InfoCircelYellow from '../../../../assets/svg/info-circle-yellow.svg'
export default {
  name: 'TestAPI',
  props: ['data', 'widgetDetails'],
  setup() {
    const { validateUrl } = useValidateFields()

    return { validateUrl }
  },
  data () {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      formSubmitted: false,
      saveLoadingIcon: false,
      streetNames: [],
      streetNumbers: [],
      unitNumbers: [],
      cloneTestAPIModel: null,
      linkedAccountUsersList: [],
      focusedUser: false,
      userSearchTerm: '',
      linkedAccountUsersLoading: false,
      linkedAccountUserAddressesList: [],
      testAPIModel: {
        widgetCategoryID: null,
        isIntegrationWidget: false,
        apiUrl: null,
        isManual: true,
        streetName: null,
        streetNumber: null,
        unitNumber: null,
        isFormat: false,
        widgetName: null,
        chartConfigurations: [],
        widgetTblConfigurations: [],
        accessToken: null,
        userId: null,
        isPropertyDependent: true,
        linkAccountDetails: null,
        selectedAddress: null
      },
      focusedStreetName: false,
      showLinkedAccountErrorMsg: false,
      apiResponse: null,
      responseTabs: ['APIResponse', 'Exceptions', 'Notifications']
    }
  },
  components: {
    InfoCircelYellow,
    TestAPITabs: () =>
      import('../../../common/test-api-response-tabs/TestAPITabs.vue')
  },
  computed: {
    getFilterdLinkAccounts () {
      return this.data && this.data.isPropertyDependent
        ? this.linkAccounts
        : this.linkAccounts.filter((item) => !item.isForEachProperty)
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      linkAccounts: (state) => state.settings.linkAccounts,
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  mounted () {
    this.assignTestAPIData()
  },
  methods: {
    getLinkAccounts () {
      if (this.selectedTenant.length) {
        this.$store.dispatch('settings/getLinkAccounts', null).then((res) => {
          if (res.data && res.data.length) {
            const selectedLinkAccount = this.data.linkAccountDetails
              ? res.data.find(
                (linkAccount) =>
                  linkAccount.id === this.data.linkAccountDetails.id &&
                    linkAccount.authenticationType ===
                      this.data.linkAccountDetails.authenticationType
              )
              : null
            this.testAPIModel.linkAccountDetails = selectedLinkAccount
            this.cloneTestAPIModel.linkAccountDetails = cloneDeep(
              this.testAPIModel.linkAccountDetails
            )
          }
        })
      }
    },
    assignTestAPIData () {
      if (this.widgetDetails) {
        this.testAPIModel.widgetCategoryID =
          this.widgetDetails.widgetCategoryID
        this.testAPIModel.isIntegrationWidget =
          this.widgetDetails.widgetCategoryID === 5
        if (this.widgetDetails.widgetCategoryID === 5) {
          this.testAPIModel.isPropertyDependent = this.data.isPropertyDependent
          this.getLinkAccounts()
        }
        this.testAPIModel.apiUrl = this.data.apiUrl
        this.testAPIModel.widgetName = this.widgetDetails.widgetName
        this.testAPIModel.isFormat = this.data.isFormat
        this.testAPIModel.chartConfigurations =
          this.widgetDetails.chartConfigurations
        this.testAPIModel.widgetTblConfigurations =
          this.widgetDetails.widgetTblConfigurations
        this.cloneTestAPIModel = cloneDeep(this.testAPIModel)
      }
    },
    onSearchStreetNames (search, loading) {
      search = search ? search.trim() : search
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      const postObj = {
        tenantId: 0,
        streetName: search,
        streetNumber: ''
      }
      vm.$store.dispatch('widgets/getStreetNames', postObj).then((res) => {
        vm.streetNames = res && res.data ? res.data : []
        loading(false)
      })
    }, 50),
    async getStreetNumbers (streetName) {
      if (streetName) {
        const postObj = {
          tenantId: 0,
          streetName: streetName,
          streetNumber: ''
        }
        this.testAPIModel.streetNumber = ''
        this.testAPIModel.unitNumber = ''
        this.unitNumbers = []
        this.streetNumbers = []
        const res = await this.$store.dispatch(
          'widgets/getStreetNumbers',
          postObj
        )
        this.streetNumbers = res && res.data ? res.data : []
      } else {
        this.testAPIModel.streetNumber = ''
        this.testAPIModel.unitNumber = ''
        this.unitNumbers = []
        this.streetNumbers = []
      }
    },
    async getUnitNumbers (streetNumber) {
      if (streetNumber) {
        const postObj = {
          tenantId: 0,
          streetName: this.testAPIModel.streetName,
          streetNumber: streetNumber
        }
        this.testAPIModel.unitNumber = ''
        this.unitNumbers = []
        const res = await this.$store.dispatch(
          'widgets/getUnitNumbers',
          postObj
        )
        this.unitNumbers = res && res.data ? res.data : []
      }
    },
    onSearchUsers (search, loading) {
      this.userSearchTerm = search
      loading(true)
      this.searchUsersList(loading, search, this)
    },
    searchUsersList: debounce(async (loading, search, vm) => {
      await vm.getSelectedLinkedAccountUsers(search)
      loading(false)
    }, 50),
    async getSelectedLinkedAccountUsers (searchTerm = '') {
      if (this.testAPIModel.linkAccountDetails && !this.testAPIModel.isManual) {
        this.linkedAccountUsersLoading = true
        const postObj = {
          linkAccountId: this.testAPIModel.linkAccountDetails.id,
          authenticationType:
            this.testAPIModel.linkAccountDetails.authenticationType,
          searchTerm: searchTerm
        }
        this.$store
          .dispatch('widgets/getLinkedAccountUsers', postObj)
          .then((res) => {
            this.linkedAccountUsersLoading = false
            this.linkedAccountUsersList = res.data
            this.showLinkedAccountErrorMsg = !!(
              !searchTerm && !res.data.length
            )
          })
          .catch(() => {
            this.linkedAccountUsersLoading = false
            this.showLinkedAccountErrorMsg = false
            this.linkedAccountUsersList = []
          })
      }
    },
    onChangeLinkedAccount () {
      this.testAPIModel.userId = null
      this.testAPIModel.selectedAddress = null
      this.getSelectedLinkedAccountUsers()
    },
    onChangeIsManualAccessToken () {
      this.getSelectedLinkedAccountUsers()
    },
    getSelectedAddress () {
      this.testAPIModel.selectedAddress = null
      this.linkedAccountUserAddressesList = []
      const postObj = {
        userId: this.testAPIModel.userId,
        linkAccountId: this.testAPIModel.linkAccountDetails.id,
        authenticationType:
          this.testAPIModel.linkAccountDetails.authenticationType,
        isForEachProperty:
          this.testAPIModel.linkAccountDetails.isForEachProperty
      }
      if (this.testAPIModel.userId) {
        this.$store
          .dispatch('widgets/getLinkedAccountUserAddresses', postObj)
          .then((res) => {
            this.linkedAccountUserAddressesList = res.data
          })
          .catch(() => {
            this.linkedAccountUserAddressesList = []
          })
      } else {
        this.linkedAccountUserAddressesList = []
      }
    },
    onResetTestAPIForm () {
      this.formSubmitted = false
      this.streetNames = []
      this.streetNumbers = []
      this.unitNumbers = []
      this.linkedAccountUsersList = []
      this.linkedAccountUserAddressesList = []
      this.showLinkedAccountErrorMsg = false
      this.testAPIModel = cloneDeep(this.cloneTestAPIModel)
    },
    validateTestAPIForm () {
      const apiUrlError = this.validateUrl(this.testAPIModel.apiUrl)
      const accessToken =
        this.testAPIModel.widgetCategoryID === 5 && this.testAPIModel.isManual
          ? !!this.testAPIModel.accessToken
          : true
      const linkAccount =
        this.testAPIModel.widgetCategoryID === 5
          ? !!this.testAPIModel.linkAccountDetails
          : true
      const streetNameError =
        this.testAPIModel.isPropertyDependent && this.testAPIModel.isManual
          ? !!this.testAPIModel.streetName
          : true
      const streetNumberError =
        this.testAPIModel.isPropertyDependent && this.testAPIModel.isManual
          ? !!this.testAPIModel.streetNumber
          : true
      const unitNumberError =
        this.testAPIModel.isPropertyDependent &&
        this.testAPIModel.isManual &&
        this.unitNumbers.length
          ? !!this.testAPIModel.unitNumber
          : true
      const userId =
        this.testAPIModel.widgetCategoryID === 5 && !this.testAPIModel.isManual
          ? !!this.testAPIModel.userId
          : true
      const selectedAddress =
        this.testAPIModel.widgetCategoryID === 5 &&
        !this.testAPIModel.isManual &&
        this.testAPIModel.isPropertyDependent
          ? !!this.testAPIModel.selectedAddress
          : true
      this.scrollToErrorMessage()
      return (
        apiUrlError &&
        accessToken &&
        linkAccount &&
        streetNameError &&
        streetNumberError &&
        unitNumberError &&
        userId &&
        selectedAddress
      )
    },
    onSubmitTestAPI () {
      this.formSubmitted = true
      this.apiResponse = null
      if (this.validateTestAPIForm()) {
        this.saveLoadingIcon = true
        this.$store
          .dispatch('widgets/executeTestAPI', this.testAPIModel)
          .then((res) => {
            this.saveLoadingIcon = false
            this.apiResponse = res.data
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger'
            })
          })
      }
    }
  }
}
</script>
