var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form',{attrs:{"id":"test-api-form","name":"test-api-form","autocomplete":"off","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitTestAPI.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                'is-invalid':
                  (!_vm.testAPIModel.apiUrl && _vm.formSubmitted) ||
                  (!_vm.validateUrl(_vm.testAPIModel.apiUrl) && _vm.formSubmitted),
              },attrs:{"id":"api-endpoint-to-test","type":"text","placeholder":" ","required":""},model:{value:(_vm.testAPIModel.apiUrl),callback:function ($$v) {_vm.$set(_vm.testAPIModel, "apiUrl", $$v)},expression:"testAPIModel.apiUrl"}}),_c('label',{attrs:{"for":"api-endpoint-to-test"}},[_vm._v("API endpoint to test")]),(!_vm.testAPIModel.apiUrl && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("API endpoint to test required.")]):_vm._e(),(
                _vm.testAPIModel.apiUrl &&
                _vm.formSubmitted &&
                !_vm.validateUrl(_vm.testAPIModel.apiUrl)
              )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Invalid url.")]):_vm._e()],1)])],1)],1),(_vm.testAPIModel.widgetCategoryID == 5)?[_c('b-form-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('b-form-select',{staticClass:"form-control",class:{
                  'is-value-exist': _vm.testAPIModel.linkAccountDetails != null,
                  'is-invalid':
                    !_vm.testAPIModel.linkAccountDetails && _vm.formSubmitted,
                },attrs:{"id":"link-account"},on:{"change":_vm.onChangeLinkedAccount,"input":function($event){_vm.testAPIModel.linkAccountDetails = $event}},model:{value:(_vm.testAPIModel.linkAccountDetails),callback:function ($$v) {_vm.$set(_vm.testAPIModel, "linkAccountDetails", $$v)},expression:"testAPIModel.linkAccountDetails"}},_vm._l((_vm.getFilterdLinkAccounts),function(linkAccount,index){return _c('b-form-select-option',{key:index,attrs:{"value":linkAccount}},[_vm._v(_vm._s(linkAccount.accountName))])}),1),_c('label',{attrs:{"for":"link-account"}},[_vm._v("Select link account")]),(!_vm.testAPIModel.linkAccountDetails && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Link account required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Access token")]),_c('b-form-radio-group',{attrs:{"name":"test-api-access-token-radio","aria-describedby":"access token selection"},on:{"change":_vm.onChangeIsManualAccessToken},model:{value:(_vm.testAPIModel.isManual),callback:function ($$v) {_vm.$set(_vm.testAPIModel, "isManual", $$v)},expression:"testAPIModel.isManual"}},[_c('b-form-radio',{attrs:{"value":true}},[_vm._v("Manual ")]),_c('b-form-radio',{attrs:{"value":false}},[_vm._v("User based")])],1)],1)],1)],1),(_vm.testAPIModel.isManual)?_c('b-form-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                  'is-invalid': !_vm.testAPIModel.accessToken && _vm.formSubmitted,
                },attrs:{"id":"access-token","type":"text","placeholder":" ","required":""},model:{value:(_vm.testAPIModel.accessToken),callback:function ($$v) {_vm.$set(_vm.testAPIModel, "accessToken", $$v)},expression:"testAPIModel.accessToken"}}),_c('label',{attrs:{"for":"access-token"}},[_vm._v("Access token")]),(!_vm.testAPIModel.accessToken && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Access token required.")]):_vm._e()],1)])],1)],1):_vm._e(),(!_vm.testAPIModel.isManual)?[(_vm.showLinkedAccountErrorMsg)?_c('b-form-row',[_c('b-col',[_c('h6',{staticClass:"font-14"},[_c('InfoCircelYellow',{staticClass:"mr-1 mt-n1"}),_vm._v(" "+_vm._s(_vm.DISPLAY_MESSAGES.TEST_API_LINKED_USERLIST_ERROR)+" ")],1)])],1):_vm._e(),_c('b-form-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('v-select',{class:{
                    'is-value-exist': _vm.testAPIModel.userId || _vm.focusedUser,
                    'is-invalid': !_vm.testAPIModel.userId && _vm.formSubmitted,
                  },attrs:{"label":"email","reduce":(email) => email.userId,"disabled":!_vm.linkedAccountUsersList.length && !_vm.userSearchTerm,"options":_vm.linkedAccountUsersList},on:{"open":function($event){_vm.focusedUser = true},"close":function($event){_vm.focusedUser = false},"search":_vm.onSearchUsers,"input":_vm.getSelectedAddress},scopedSlots:_vm._u([{key:"no-options",fn:function({ search }){return [_vm._v(" "+_vm._s(search ? `No users found.` : `Start typing user, and select it from the dropdown.`)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.email)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.email)+" ")]}}],null,false,3775393560),model:{value:(_vm.testAPIModel.userId),callback:function ($$v) {_vm.$set(_vm.testAPIModel, "userId", $$v)},expression:"testAPIModel.userId"}}),_c('label',{attrs:{"for":"calendar-type"}},[_vm._v("Select user")]),(!_vm.testAPIModel.userId && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("User required.")]):_vm._e()],1)])],1)],1),(_vm.testAPIModel.isPropertyDependent)?_c('b-form-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('v-select',{class:{
                    'is-value-exist': _vm.testAPIModel.selectedAddress,
                    'is-invalid':
                      !_vm.testAPIModel.selectedAddress && _vm.formSubmitted,
                  },attrs:{"label":"fullAddress","id":"id","disabled":!_vm.testAPIModel.userId ||
                    !_vm.linkedAccountUserAddressesList.length,"clear-search-on-blur":(clearSearchOnSelect, multiple) =>
                      clearSearchOnSelect && !multiple,"options":_vm.linkedAccountUserAddressesList,"reduce":(fullAddress) => fullAddress},scopedSlots:_vm._u([{key:"no-options",fn:function({ search }){return [_vm._v(" "+_vm._s(search ? `No address found.` : `Start typing address, and select it from the dropdown.`)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullAddress)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullAddress)+" ")]}}],null,false,805795851),model:{value:(_vm.testAPIModel.selectedAddress),callback:function ($$v) {_vm.$set(_vm.testAPIModel, "selectedAddress", $$v)},expression:"testAPIModel.selectedAddress"}}),_c('label',{attrs:{"for":"calendar-type"}},[_vm._v("Select address")]),(!_vm.testAPIModel.selectedAddress && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Address required.")]):_vm._e()],1)])],1)],1):_vm._e()]:_vm._e()]:_vm._e(),(_vm.testAPIModel.isManual && _vm.testAPIModel.isPropertyDependent)?[_c('b-form-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('v-select',{class:{
                  'is-value-exist':
                    _vm.testAPIModel.streetName || _vm.focusedStreetName,
                  'is-invalid': !_vm.testAPIModel.streetName && _vm.formSubmitted,
                },attrs:{"label":"name","id":`street-name`,"filterable":false,"options":_vm.streetNames},on:{"open":function($event){_vm.focusedStreetName = true},"close":function($event){_vm.focusedStreetName = false},"search":_vm.onSearchStreetNames,"input":function($event){return _vm.getStreetNumbers($event)}},scopedSlots:_vm._u([{key:"no-options",fn:function({ search }){return [_vm._v(" "+_vm._s(search && search.trim() ? `No street names found.` : `Start typing your street name, and select it from the dropdown.`)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,1928414282),model:{value:(_vm.testAPIModel.streetName),callback:function ($$v) {_vm.$set(_vm.testAPIModel, "streetName", $$v)},expression:"testAPIModel.streetName"}}),_c('label',{attrs:{"for":`street-name`}},[_vm._v("Street name")]),(!_vm.testAPIModel.streetName && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Street name required.")]):_vm._e()],1)])],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.testAPIModel.streetNumber),expression:"testAPIModel.streetNumber"}],staticClass:"form-control",class:{
                  'is-value-exist': _vm.testAPIModel.streetNumber,
                  'is-invalid': !_vm.testAPIModel.streetNumber && _vm.formSubmitted,
                },attrs:{"id":`street-number`,"disabled":!_vm.testAPIModel.streetName},on:{"input":function($event){_vm.testAPIModel.streetNumber = $event.target.value},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.testAPIModel, "streetNumber", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getUnitNumbers($event.target.value)}]}},_vm._l((_vm.streetNumbers),function(streetNumber,si){return _c('option',{key:si,domProps:{"value":streetNumber}},[_vm._v(" "+_vm._s(streetNumber)+" ")])}),0),_c('label',{attrs:{"for":`street-number`}},[_vm._v("Street number")]),(!_vm.testAPIModel.streetNumber && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Street number required.")]):_vm._e()],1)])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[(_vm.unitNumbers)?_c('div',{staticClass:"floating-select-field"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.testAPIModel.unitNumber),expression:"testAPIModel.unitNumber"}],staticClass:"form-control",class:{
                  'is-value-exist': _vm.testAPIModel.unitNumber,
                  'is-invalid':
                    _vm.unitNumbers.length &&
                    !_vm.testAPIModel.unitNumber &&
                    _vm.formSubmitted,
                },attrs:{"id":`unit-number`,"disabled":!_vm.testAPIModel.streetNumber || !_vm.unitNumbers.length},on:{"input":function($event){_vm.testAPIModel.unitNumber = $event.target.value},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.testAPIModel, "unitNumber", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.testAPIModel.unitNumber = $event.target.value}]}},_vm._l((_vm.unitNumbers),function(unitNumber,ui){return _c('option',{key:ui,domProps:{"value":unitNumber}},[_vm._v(" "+_vm._s(unitNumber)+" ")])}),0),_c('label',{attrs:{"for":`unit-number`}},[_vm._v("Apt / unit number")]),(
                  _vm.unitNumbers.length &&
                  !_vm.testAPIModel.unitNumber &&
                  _vm.formSubmitted
                )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Apt / unit number required.")]):_vm._e()],1):_vm._e()])],1)],1)]:_vm._e(),_c('b-form-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"sm":"8"}},[_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],attrs:{"type":"submit","variant":"secondary","disabled":_vm.saveLoadingIcon}},[_vm._v("Run "),(_vm.saveLoadingIcon)?_c('b-spinner',{staticClass:"ml-2",attrs:{"label":"Spinning","small":""}}):_vm._e()],1),_c('b-button',{directives:[{name:"activeBlur",rawName:"v-activeBlur"}],staticClass:"ml-3",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.onResetTestAPIForm}},[_vm._v("Cancel")])],1)],1)],2),_c('TestAPITabs',{attrs:{"apiResponse":_vm.apiResponse,"tabs":_vm.responseTabs}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }